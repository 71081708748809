import { Component, Vue } from "vue-property-decorator";
import API from "@api";
import { Ref } from "vue-property-decorator";
import { VForm } from "@/types/vuetify";

const countdownSeconds = 60;

@Component
export default class VerifyCodeForm extends Vue {
  @Ref("form") private readonly formRef!: VForm;

  private countdownSeconds = countdownSeconds;
  private countdownIntervalId = 0;
  private verifyingCode = false;
  private sendingCode = false;
  private codeValue = "";

  private initCountdown() {
    window.clearInterval(this.countdownIntervalId);
    this.countdownSeconds = countdownSeconds;

    this.countdownIntervalId = window.setInterval(() => {
      if (--this.countdownSeconds > 0) return;

      window.clearInterval(this.countdownIntervalId);
    }, 1e3);
  }

  private async verifyCode() {
    if (!this.formRef.validate() || this.verifyingCode) return;

    this.verifyingCode = true;

    try {
      await API.profile.verifyConfirmCode({ code: this.codeValue });

      this.verifyingCode = false;

      this.$emit("verified");
    } catch {
      this.verifyingCode = false;
    }
  }

  private async requestCodeAgain() {
    if (this.sendingCode) return;

    this.sendingCode = true;
    try {
      await API.profile.sendConfirmCode();

      this.sendingCode = false;
      this.initCountdown();
    } catch {
      this.sendingCode = false;
    }
  }

  private onFinishInputCode() {
    this.verifyCode();
  }

  private mounted() {
    this.initCountdown();
  }

  private beforeDestroy() {
    window.clearInterval(this.countdownIntervalId);
  }
}
